import React from 'react';
import './AutomationIndicator.css';

const AutomatisierungIndicator = ({ level }) => {
    const getIndicatorClass = () => {
        switch (level) {
            case 'Hoch':
                return 'high';
            case 'Mittel':
                return 'medium';
            case 'Niedrig':
                return 'low';
            default:
                return '';
        }
    };

    return (
        <div className={`automatisierung-indicator ${getIndicatorClass()}`}>
            <div className="indicator-bar"></div>
            <div className="indicator-bar"></div>
            <div className="indicator-bar"></div>
        </div>
    );
};

export default AutomatisierungIndicator;
