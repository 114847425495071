import React, { useState, useEffect } from 'react';
import businessCases from './mappings_01_07.json'; // Ensure the JSON is correctly imported
import AutomationIndicator from './AutomationIndicator.js'; // Import the new component
import './App.css';

const App = () => {
    const [formValues, setFormValues] = useState({
        "product-category": "Schüttgut",
        "service-type": "Material inkl. Transport",
        "quantity": "Bis 260t",
        "price-scenario": "Kunde Frei Bau, Material und Transportleistung werden getrennt vergütet",
        "kunde-org-angelegt": "TRUE",
        "kunde-app-agb-bestatigt": "TRUE",
        "kunde-bauvorhaben-angelegt": "TRUE",
        "kunde-bestellt-selber": "TRUE",
        "anbieter-org-angelegt": "TRUE",
        "anbieter-app-agb-bestatigt": "TRUE",
        "anbieter-werk-katalog-angelegt": "TRUE",
        "anbieter-spot-market-aktiv": "TRUE",
        "anbieter-zertifikate-hinterlegt": "TRUE",
        "spediteur-org-angelegt": "TRUE",
        "spediteur-app-agb-bestatigt": "TRUE",
        "spediteur-fuhrpark-angelegt": "TRUE",
        "spediteur-zertifikate-hinterlegt": "TRUE",
        "spediteur-nutzung-transportboerse": "TRUE"
    });
  
    const [partnerType, setPartnerType] = useState("Partner ist Anbieter und Spediteur");
    const [result, setResult] = useState(null);
    const [userId, setUserId] = useState(null);
    const [env, setEnv] = useState('production'); // Default to production

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const userIdParam = urlParams.get('userId');
        const envParam = urlParams.get('env') || 'production'; // Default to production if env is not specified

        setUserId(userIdParam);
        setEnv(envParam);
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setFormValues(prevState => ({ ...prevState, [name]: checked ? "TRUE" : "FALSE" }));
        } else {
            setFormValues(prevState => ({ ...prevState, [name]: value }));

            // Reset price-scenario when related fields change
            if (name === "product-category" || name === "service-type") {
                setFormValues(prevState => ({ ...prevState, "price-scenario": "" }));
            }
        }
    };

    const handlePartnerTypeChange = (e) => {
        setPartnerType(e.target.value);
    };


    const matchCondition = (storedValue, inputValue) => {
        if (!inputValue) {
            return storedValue === undefined || storedValue === null; // Match only if the stored value is also undefined or null
        }
        if (Array.isArray(storedValue)) {
            return storedValue.includes(inputValue);
        }
        return storedValue.trim() === inputValue.trim();
    };

    const findMatch = (frontendData, mappingData) => {
        for (const entry of mappingData) {
            const match = Object.keys(entry.inputs).every(key => {
                if (!(key in frontendData)) {
                    return entry.inputs[key] === undefined; // Skip keys not present in frontend data unless the stored value is undefined
                }
                return matchCondition(entry.inputs[key], frontendData[key]);
            });

            if (match) {
                return entry;
            }
        }
        return null;
    };

    useEffect(() => {
        const filteredPriceScenarios = [
            { value: "Kunde Frei Bau, Material und Transportleistung werden getrennt vergütet", label: "Kunde Frei Bau, Material und Transportleistung werden getrennt vergütet" },
            { value: "Kunde Frei Bau, Partner Frei Bau", label: "Kunde Frei Bau, Partner Frei Bau" },
            { value: "Material und Transport werden einzeln abgerechnet und vergütet", label: "Material und Transport werden einzeln abgerechnet und vergütet" },
            { value: "pro Tonne", label: "pro Tonne" },
            { value: "andere Einheit", label: "andere Einheit" },
            { value: "pro Transport", label: "pro Transport" },
        ].filter(option => {
            if (formValues["service-type"] === "Nur Transport") {
                return [
                    "pro Tonne", "andere Einheit",
                    "pro Transport"
                ].includes(option.value);
            }
            if (formValues["service-type"] === "Nur Material") {
                return [
                    "pro Tonne", "andere Einheit"
                ].includes(option.value);
            }
            if (formValues["service-type"] === "Material inkl. Transport") {
                return [
                    "Kunde Frei Bau, Material und Transportleistung werden getrennt vergütet",
                    "Kunde Frei Bau, Partner Frei Bau",
                    "Material und Transport werden einzeln abgerechnet und vergütet",
                ].includes(option.value);
            }
            if (formValues["product-category"] === "Nicht- gefährlicher Abfall" && formValues["service-type"] === "Nur Material") {
                return [
                    "pro Tonne"
                ].includes(option.value);
            }
            return false;
        });

        if (filteredPriceScenarios.length > 0 && !filteredPriceScenarios.some(option => option.value === formValues["price-scenario"])) {
            setFormValues(prevState => ({ ...prevState, "price-scenario": filteredPriceScenarios[0].value }));
        }
    }, [formValues["product-category"], formValues["service-type"]]);

    const handleSubmit = async (e) => {

        e.preventDefault();
        const filteredFormValues = { ...formValues };

        // Remove "Spediteur" fields if the service type is "Nur Material"
        if (formValues["service-type"] === "Nur Material" || partnerType === "Partner ist nur Anbieter") {
            ["spediteur-org-angelegt", "spediteur-app-agb-bestatigt", "spediteur-fuhrpark-angelegt", "spediteur-zertifikate-hinterlegt", "spediteur-nutzung-transportboerse"].forEach(field => {
                delete filteredFormValues[field];
            });
        }

        // Remove "Anbieter" fields if the service type is "Nur Transport"
        if (formValues["service-type"] === "Nur Transport" || partnerType === "Partner ist nur Spediteur") {
            ["anbieter-org-angelegt", "anbieter-app-agb-bestatigt", "anbieter-werk-katalog-angelegt", "anbieter-spot-market-aktiv", "anbieter-zertifikate-hinterlegt"].forEach(field => {
                delete filteredFormValues[field];
            });
        }

        console.log("Filtered form values being submitted:", filteredFormValues);  // Log filtered form values
        const match = findMatch(filteredFormValues, businessCases);

        if (match) {
            setResult({
                status: "Match found",
                bestellart: match.outputs.bestellart,
                id: match.outputs.id,
                vorgehen: match.outputs.vorgehen.split('\n'),
                hinweis: match.outputs.hinweis,
                automatisierung: match.outputs.automatisierung,
                anmerkung: match.outputs.anmerkung,
                problem: match.outputs.problem,
                link: match.outputs.link // Add link here if not present
            });
                // Track the search result
                const currentDateTime = new Date().toLocaleString('de-DE', { timeZone: 'Europe/Berlin' });
                try {
                await fetch('http://localhost:3001/api/track', { // Ensure this URL points to your backend server
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                id: match.outputs.id,
                formData: filteredFormValues,
                timestamp: currentDateTime,
                userId: userId,
                env: env,
                })
            });
            } catch (error) {
            console.error('Error tracking the search result:', error);
            }
        } else {
            setResult({ status: "No match found" });
        }
        };


    const handleReset = () => {
        setFormValues({
            "product-category": "Schüttgut",
            "service-type": "Material inkl. Transport",
            "quantity": "Bis 260t",
            "price-scenario": "Kunde Frei Bau, Material und Transportleistung werden getrennt vergütet",
            "kunde-org-angelegt": "TRUE",
            "kunde-app-agb-bestatigt": "TRUE",
            "kunde-bauvorhaben-angelegt": "TRUE",
            "kunde-bestellt-selber": "TRUE",
            "anbieter-org-angelegt": "TRUE",
            "anbieter-app-agb-bestatigt": "TRUE",
            "anbieter-werk-katalog-angelegt": "TRUE",
            "anbieter-spot-market-aktiv": "TRUE",
            "anbieter-zertifikate-hinterlegt": "TRUE",
            "spediteur-org-angelegt": "TRUE",
            "spediteur-app-agb-bestatigt": "TRUE",
            "spediteur-fuhrpark-angelegt": "TRUE",
            "spediteur-zertifikate-hinterlegt": "TRUE",
            "spediteur-nutzung-transportboerse": "TRUE"
        });
        setPartnerType("Partner ist Anbieter und Spediteur");

        setResult(null);
    };

    const filteredServiceTypeScenarios = [
        { value: "Material inkl. Transport", label: "Material inkl. Transport" },
        { value: "Nur Material", label: "Nur Material" },
        { value: "Nur Transport", label: "Nur Transport" },
    ];

    const filteredPriceScenarios = [
        { value: "Kunde Frei Bau, Material und Transportleistung werden getrennt vergütet", label: "Kunde Frei Bau, Material und Transportleistung werden getrennt vergütet" },
        { value: "Kunde Frei Bau, Partner Frei Bau", label: "Kunde Frei Bau, Partner Frei Bau" },
        { value: "Material und Transport werden einzeln abgerechnet und vergütet", label: "Material und Transport werden einzeln abgerechnet und vergütet" },
        { value: "pro Tonne", label: "pro Tonne" },
        { value: "andere Einheit", label: "andere Einheit" },
        { value: "pro Transport", label: "pro Transport" },
    ].filter(option => {
        if (formValues["service-type"] === "Nur Transport") {
            return [
                "pro Tonne",
                "andere Einheit",
                "pro Transport",
            ].includes(option.value);
        }
        if (formValues["service-type"] === "Nur Material") {
            return [
                "pro Tonne",
                "andere Einheit",
            ].includes(option.value);
        }
        if (formValues["service-type"] === "Material inkl. Transport") {
            return [
                "Kunde Frei Bau, Material und Transportleistung werden getrennt vergütet",
                "Kunde Frei Bau, Partner Frei Bau",
                "Material und Transport werden einzeln abgerechnet und vergütet",
            ].includes(option.value);
        }
        if (formValues["product-category"] === "Nicht- gefährlicher Abfall" && formValues["service-type"] === "Nur Material") {
            return [
                "pro Tonne",
            ].includes(option.value);
        }
        return false;
    });

    const filteredQuantityScenarios = [
        { value: "Bis 260t", label: "Bis 260t" },
        { value: "Bis 100 Transporte", label: "Bis 100 Transporte" },
        { value: "Projekt", label: "Projekt" },
    ];

    return (
        <div className="container">
            <div className="content">
                <div className="headline-copy-instance">
                    <h2>Bestell-Assitent</h2>
                    <p>Gib hier deinen Geschäftsfall ein um die passende Bestellart zu ermitteln.</p>
                </div>
                <form onSubmit={handleSubmit} className="form">
                    <div className="frame-2">
                        <div className="frame-3">
                            <label htmlFor="product-category" className="text-wrapper-5">Material</label>
                            <select id="product-category" name="product-category" value={formValues["product-category"]} onChange={handleChange} className="select-new-instance">
                                <option value="Schüttgut">Schüttgut</option>
                                <option value="Nicht- gefährlicher Abfall">Nicht- gefährlicher Abfall</option>
                                <option value="Gefährlichen Abfall">Gefährlichen Abfall</option>
                                <option value="Beton">Beton</option>
                                <option value="Asphalt">Asphalt</option>
                                <option value="Sonstige Materialien">Sonstige Materialien</option>
                            </select>
                        </div>
                        <div className="frame-3">
                            <label htmlFor="service-type" className="text-wrapper-5">Leistungsart</label>
                            <select id="service-type" name="service-type" value={formValues["service-type"]} onChange={handleChange} className="select-new-instance">
                                {filteredServiceTypeScenarios.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="frame-3">
                            <label htmlFor="price-scenario" className="text-wrapper-5">Abrechnung</label>
                            <select id="price-scenario" name="price-scenario" value={formValues["price-scenario"]} onChange={handleChange} className="select-new-instance">
                                {filteredPriceScenarios.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="frame-3">
                            <label htmlFor="quantity" className="text-wrapper-5">Menge</label>
                            <select id="quantity" name="quantity" value={formValues["quantity"]} onChange={handleChange} className="select-new-instance">
                                {filteredQuantityScenarios.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        {formValues["price-scenario"] === "Kunde Frei Bau, Partner Frei Bau" && (
                            <div className="frame-3">
                              <label htmlFor="partner-type" className="text-wrapper-5">Als was ist der Partner auf SFLX angelegt?</label>
                                <select id="partner-type" name="product-category" value={partnerType["partner-type"]} onChange={handlePartnerTypeChange} className="select-new-instance">
                                    <option value="Partner ist Anbieter und Spediteur">Partner ist Anbieter und Spediteur</option>
                                    <option value="Partner ist nur Anbieter">Partner ist nur Anbieter</option>
                                    <option value="Partner ist nur Spediteur">Partner ist nur Spediteur</option>
                            </select>
                            </div>
                        )}
                    </div>
                    <div className="frame-5">
                        <div className="frame-6">
                            <div className="text-wrapper-5">Was trifft zu?</div>
                        </div>
                        <div className="radio-button-base">
                            <div className="frame-7">
                                <div className="text-wrapper-5">Kunde</div>
                            </div>
                            <div className="radio-option">
                                <input type="checkbox" id="kunde-org-angelegt" name="kunde-org-angelegt" checked={formValues["kunde-org-angelegt"] === "TRUE"} onChange={handleChange} />
                                <label htmlFor="kunde-org-angelegt">Organisation angelegt</label>
                            </div>
                            <div className="radio-option">
                                <input type="checkbox" id="kunde-app-agb-bestatigt" name="kunde-app-agb-bestatigt" checked={formValues["kunde-app-agb-bestatigt"] === "TRUE"} onChange={handleChange} />
                                <label htmlFor="kunde-app-agb-bestatigt">AGBs in der App bestätigt</label>
                            </div>
                            <div className="radio-option">
                                <input type="checkbox" id="kunde-bauvorhaben-angelegt" name="kunde-bauvorhaben-angelegt" checked={formValues["kunde-bauvorhaben-angelegt"] === "TRUE"} onChange={handleChange} />
                                <label htmlFor="kunde-bauvorhaben-angelegt">Bauvorhaben angelegt</label>
                            </div>
                            <div className="radio-option">
                                <input type="checkbox" id="kunde-bestellt-selber" name="kunde-bestellt-selber" checked={formValues["kunde-bestellt-selber"] === "TRUE"} onChange={handleChange} />
                                <label htmlFor="kunde-bestellt-selber">Kunde bestellt selber</label>
                            </div>
                        </div>
                        {formValues["service-type"] !== "Nur Transport" && partnerType !== "Partner ist nur Spediteur" && (
                            <div className="radio-button-base">
                                <div className="frame-8">
                                    <div className="text-wrapper-5">Anbieter</div>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="anbieter-org-angelegt" name="anbieter-org-angelegt" checked={formValues["anbieter-org-angelegt"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="anbieter-org-angelegt">Organisation angelegt</label>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="anbieter-app-agb-bestatigt" name="anbieter-app-agb-bestatigt" checked={formValues["anbieter-app-agb-bestatigt"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="anbieter-app-agb-bestatigt">AGBs in der App bestätigt</label>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="anbieter-werk-katalog-angelegt" name="anbieter-werk-katalog-angelegt" checked={formValues["anbieter-werk-katalog-angelegt"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="anbieter-werk-katalog-angelegt">Werk und Katalog angelegt</label>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="anbieter-spot-market-aktiv" name="anbieter-spot-market-aktiv" checked={formValues["anbieter-spot-market-aktiv"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="anbieter-spot-market-aktiv">Spot Market am Produkt aktiv</label>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="anbieter-zertifikate-hinterlegt" name="anbieter-zertifikate-hinterlegt" checked={formValues["anbieter-zertifikate-hinterlegt"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="anbieter-zertifikate-hinterlegt">Zertifikate hinterlegt</label>
                                </div>
                            </div>
                        )}
                        {formValues["service-type"] !== "Nur Material" && partnerType !== "Partner ist nur Anbieter" && (


                            <div className="radio-button-base">
                                <div className="frame-8">
                                    <div className="text-wrapper-5">Spediteur</div>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="spediteur-org-angelegt" name="spediteur-org-angelegt" checked={formValues["spediteur-org-angelegt"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="spediteur-org-angelegt">Organisation angelegt</label>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="spediteur-app-agb-bestatigt" name="spediteur-app-agb-bestatigt" checked={formValues["spediteur-app-agb-bestatigt"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="spediteur-app-agb-bestatigt">AGBs in der App bestätigt</label>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="spediteur-fuhrpark-angelegt" name="spediteur-fuhrpark-angelegt" checked={formValues["spediteur-fuhrpark-angelegt"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="spediteur-fuhrpark-angelegt">Fuhrpark angelegt</label>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="spediteur-zertifikate-hinterlegt" name="spediteur-zertifikate-hinterlegt" checked={formValues["spediteur-zertifikate-hinterlegt"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="spediteur-zertifikate-hinterlegt">Zertifikate hinterlegt</label>
                                </div>
                                <div className="radio-option">
                                    <input type="checkbox" id="spediteur-nutzung-transportboerse" name="spediteur-nutzung-transportboerse" checked={formValues["spediteur-nutzung-transportboerse"] === "TRUE"} onChange={handleChange} />
                                    <label htmlFor="spediteur-nutzung-transportboerse">Nutzung Transportbörse</label>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="button-wrapper">
                        <button type="submit" className="button">Absenden</button>
                    </div>
                </form>
                {result && (
                    <div className="result">
                    {result.status === "Match found" ? (
                        <>
                            <h3>Zu nutzende Bestellart</h3>
                            <h2>{result.bestellart} --{'>'} ID = {result.id}</h2>
                            <div className="automatisierung-section">
                                    <p>Automatisierung</p>
                                    <AutomationIndicator level={result.automatisierung} />
                            </div>
                            <div className="result-card">
                                <strong>Vorgehen</strong>
                                <div className="result-section">
                                    <ul>
                                        {result.vorgehen.map((item, index) => (
                                         <li key={index}>{item}</li>
                                        ))}
                                </ul>                               
                                </div>
                                <div className="result-section">
                                    <strong>Hinweis</strong>
                                    <p>{result.hinweis}</p>
                                </div>
                            </div>
                            <div className="button-group">
                                <a href="#" onClick={handleReset} className="reset-link">
                                    Erneut ausfüllen
                                </a>
                                {result.link && result.link !== "-" && (
                                    <button className="order-button" onClick={() => window.open(result.link)}>
                                        Zur Bestellart
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="no-match">
                            <h3>Kein Treffer</h3>
                            <p>Es konnte keine passende Bestellart gefunden werden.</p>
                            <button className="button reset-button" onClick={handleReset}>Erneut ausfüllen</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    </div>
);
};

export default App;

